import './sass/style.scss'

import instantsearch from 'instantsearch.js'
import { clearRefinements, configure, hits, infiniteHits, pagination, searchBox, sortBy, currentRefinements, stats } from 'instantsearch.js/es/widgets'
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter'
import { getHitsParams } from './instant-search/hits'
import { getInfiniteHitsParams } from './instant-search/infiniteHits'
import { getConfigureParams } from './instant-search/configure'
import { getPaginationParams } from './instant-search/pagination'
import { analyticsMiddleware } from './instant-search/analyticsMiddleware'
import { onRenderHandler } from './instant-search/onRenderHandler'
import { getSearchBoxParams } from './instant-search/searchBox'
import { getSortByParams } from './instant-search/sortBy'
import { getFacetParams } from './instant-search/facet'
import { mobileFilter, toggleCollectionSwitcherMenuHandler } from './instant-search/mobileFilter'
import getClearRefinements from './instant-search/getClearRefinements'
import getStats from './instant-search/stats'
import { getCurrentRefinementsParams } from './instant-search/currentRefinements'

let parsedSettings = window.hasOwnProperty('cm_typesense_instant_search_default_settings') ? window.cm_typesense_instant_search_default_settings : {}
const defaultSettings = {
  elementor_edit_mode: false,
  protocol: 'https://',
  localized_strings: {
    load_more: 'Load More',
    show_more: 'Show More',
  },
  search_api_key: '', ...parsedSettings,
}
const protocol = defaultSettings.protocol !== 'https://' ? 'http' : 'https'
const localized_strings = defaultSettings.localized_strings

const swtInstance = {}

export function CMSWTInstantSearch () {
  function setActiveOnTabItem (container, collectionName) {
    // Function to remove active class from other siblings. parentElement.parentElement is done cause it's easier to deal with li inside ul than to go one level deep upto anchor element
    let indexSwitcherWrapper = container.querySelector('.cmswt-IndexSwitcher')
    //el is the <a> tag
    let el = indexSwitcherWrapper.querySelector(`[data-collection="${collectionName}"]`)
    const collectionMenu = indexSwitcherWrapper.parentElement

    //logic for opening the menu is in mobileFilter.js this is for setting active item
    //for mobile only but doesn't really matter if this code is run
    indexSwitcherWrapper.classList.remove('cmswt-IndexSwitcher--block')
    const activeCollectionBlock = collectionMenu.querySelector('.cmswt-CollectionMenu-current')
    if (activeCollectionBlock !== null) {
      activeCollectionBlock.querySelector('.cmswt-CollectionMenu-currentLabel').textContent = el.textContent
      activeCollectionBlock.classList.remove('cmswt-CollectionMenu-current--listOpen')
    }

    let siblings = indexSwitcherWrapper.querySelectorAll('.cmswt-IndexSwitcher-item')
    if (siblings.length > 0) {
      siblings.forEach(sibling => sibling.classList.remove('active'))
    }
    el.parentElement.classList.add('active')
  }

  function indexClickHandler (e) {
    e.preventDefault()
    let el = e.currentTarget
    let instanceID = el.getAttribute('data-instance_id')
    let collectionName = el.getAttribute('data-collection')
    let lastQuery = swtInstance[instanceID].helper.state.query ? swtInstance[instanceID].helper.state.query : ''
    let instanceContainer = el.closest(`.${instanceID}`)
    initIndex(instanceContainer, instanceID, collectionName, lastQuery)
  }

  function initIndex (instanceContainer, instanceID, collectionName, lastQuery) {
    destroySearchInstance(instanceID)
    setActiveOnTabItem(instanceContainer, collectionName)
    initializeSearch(instanceContainer, collectionName, lastQuery)
  }

  function destroySearchInstance (instanceID) {
    //destroy previous instance before we start initialization
    if (swtInstance.hasOwnProperty(instanceID)) {
      swtInstance[instanceID].dispose()
    }
  }

  function initializeSearch (searchWrapperEl, collectionName, lastQuery='') {
    let config = JSON.parse(searchWrapperEl.dataset.config)
    let additionalConfig = JSON.parse(searchWrapperEl.dataset.additional_config)
    let facets = JSON.parse(searchWrapperEl.dataset.facets)
    let placeholder = searchWrapperEl.dataset.placeholder !== undefined ? searchWrapperEl.dataset.placeholder : 'Search for...'
    let queryBy = searchWrapperEl.dataset.query_by !== undefined ? searchWrapperEl.dataset.query_by : 'post_title,post_content'
    let sticky_first = searchWrapperEl.dataset.sticky_first
    // Get query string from previous tab
    let queryEl = searchWrapperEl.querySelector('.ais-SearchBox-input')
    let defaultIndex = collectionName
    let defaultSearchResultEl = searchWrapperEl.getElementsByClassName('cmswt-Result-hits_' + defaultIndex)
    let searchQuery = null
    let searchBoxEl = searchWrapperEl.getElementsByClassName('cmswt-SearchBox')
    let currentRefinementsEl = searchWrapperEl.querySelector('.cmswt-CurrentRefinements')
    let clearRefinementsEl = searchWrapperEl.querySelector('.cmswt-ClearRefinements')
    let statsEl = searchWrapperEl.querySelector('.cmswt-Stats')

    const addSearchParameters = {
      query_by: queryBy,
    }
    //if show_stick_first
    if (sticky_first === 'yes') {
      addSearchParameters.sort_by = 'is_sticky:desc,_text_match:desc'
    }

    //get the query element
    if (queryEl !== null) {
      searchQuery = queryEl.value
    } else if (searchWrapperEl.getAttribute('data-search_query') !== '') {
      searchQuery = searchWrapperEl.getAttribute('data-search_query')
    }

    //initialize typesense instant search adapter
    const uniqueInstanceID = searchWrapperEl.dataset.id
    let allWidgets = []

    //typesense-instantsearch-adapter] Please use snake_cased versions of parameters in additionalSearchParameters instead of camelCased parameters. For example: Use query_by instead of queryBy. camelCased parameters will be deprecated in a future version. We're making this change so that parameter names are identical to the ones sent to Typesense (which are all snake_cased), and to also keep the types for these parameters in sync with the types defined in typesense-js.
    let additionalSearchParams = JSON.parse(searchWrapperEl.dataset.additional_search_params)
    let routing = searchWrapperEl.dataset.routing
    let searchParams = { ...addSearchParameters, ...additionalSearchParams }

    //initialize typesense search adapter
    const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
      server: {
        apiKey: defaultSettings.search_api_key,// Be sure to use an API key that only allows search operations
        nodes: [
          {
            host: defaultSettings.node,
            port: defaultSettings.port,
            protocol: protocol,
          }],
      }, cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
      // The following parameters are directly passed to Typesense search API endpoint.
      //  So you can pass any parameters supported by the search endpoint below.
      //  queryBy is required.
      additionalSearchParameters: searchParams,
    })

    //get searchClient
    const searchClient = typesenseInstantsearchAdapter.searchClient

    //main initialization for default index
    let shortcodeSearch = instantsearch({
      searchClient,
      indexName: defaultIndex,
      routing: 'enable' === routing,
    })

    //show or hide sortby
    if (config.sortby === 'show') {
      let sortByEl = searchWrapperEl.querySelector('.cmswt-SortBy-' + defaultIndex)
      //dont initialize if sortby element does not exist
      if (sortByEl !== null) {
        allWidgets.push(sortBy(getSortByParams(defaultIndex, sortByEl)))
      }
    }

    //show filter
    if (config.filter === 'show' && facets.hasOwnProperty(defaultIndex)) {
      facets[defaultIndex].map((facet) => {
        // querySelector throws not valid selector error if the the selctor has special characters. In our case, Woo addon has special facets like category_*. So to prevent error, we skip it.
        if (facet.includes('*')) {
          return
        }
        // let filterItem = searchWrapperEl.querySelector('.cmswt-Filter-category_.\\*')
        let filterItem = searchWrapperEl.querySelector(`.cmswt-Filter-collection_${defaultIndex}.cmswt-Filter-${facet}`)
        if (filterItem !== null) {
          let filterPanel = getFacetParams(filterItem, facet, localized_strings)
          allWidgets.push(filterPanel)
        }
      })
    }

    //add widgets to default_post_type initialize search box
    if (searchBoxEl.length > 0) {
      allWidgets.push(searchBox(getSearchBoxParams(searchBoxEl[0], placeholder)))
    }

    //if pagination is infinite we need to use InfiniteHits widget instead of hits widget do not confuse with pagination option
    if (config.pagination === 'infinite') {
      allWidgets.push(infiniteHits(getInfiniteHitsParams(defaultSearchResultEl[0], defaultIndex, config, defaultSettings, localized_strings)))
    } else {
      allWidgets.push(hits(getHitsParams(defaultSearchResultEl[0], defaultIndex, config, defaultSettings)))
    }
    //enable or disable pagination
    if (config.pagination === 'show') {
      let paginationWrapper = searchWrapperEl.querySelector('.cmswt-Pagination-' + defaultIndex)
      if (paginationWrapper !== null) {
        allWidgets.push(pagination(getPaginationParams(searchWrapperEl, paginationWrapper)))
      }
    }
    //configure params
    allWidgets.push(configure(getConfigureParams(config, searchQuery)))

    //show selected refinements
    if (currentRefinementsEl !== null) {
      allWidgets.push(currentRefinements(getCurrentRefinementsParams(currentRefinementsEl, defaultIndex, searchWrapperEl)))
    }

    //show clear refinements
    if (clearRefinementsEl !== null) {
      getClearRefinements(clearRefinementsEl)
      allWidgets.push(clearRefinements(getClearRefinements(clearRefinementsEl)))
    }

    //stats widget
    if (statsEl !== null) {
      allWidgets.push(stats(getStats(statsEl)))
    }
   
    //all widgets
    shortcodeSearch.addWidgets(allWidgets)

    //analytics middleware
    shortcodeSearch.use(analyticsMiddleware)
    shortcodeSearch.start()

    if(lastQuery && additionalConfig.preserve_search) {
      shortcodeSearch.helper.state.query = lastQuery
    }

    //Show hide FilterPanel based on if facets have been returned
    shortcodeSearch.on('render', () => { onRenderHandler(searchWrapperEl, defaultIndex) })
    swtInstance[uniqueInstanceID] = shortcodeSearch

    //Handle Toggle For Mobile Menu
    mobileFilter(searchWrapperEl)
    //toggle collection/index switcher handler
    toggleCollectionSwitcherMenuHandler(searchWrapperEl)
  }

  function init () {
    const searchWrapperEls = document.querySelectorAll('.cmswt-InstantSearch')
    //loop through all instant search wrapper instances
    if (searchWrapperEls !== null && searchWrapperEls.length > 0) {
      searchWrapperEls.forEach((searchWrapperEl) => {
        let indexWrapperEl = searchWrapperEl.querySelector('.cmswt-IndexSwitcher')
        let indexSwitcherElements = indexWrapperEl.querySelectorAll('a')
        indexSwitcherElements.forEach((indexSwitcherEl) => {
          indexSwitcherEl.addEventListener('click', indexClickHandler)
          // Trigger click event such that the element with active class shows the results cause we need to show results on first tab
          if (indexSwitcherEl.parentElement.classList.contains('active')) {
            let initialInstanceID = indexSwitcherEl.getAttribute('data-instance_id')
            let initialCollectionName = indexSwitcherEl.getAttribute('data-collection')
            initIndex(searchWrapperEl, initialInstanceID, initialCollectionName)
          }
        })
      })
    }
    //assign to global so people can hook into events
    window.swtInstance = swtInstance
  }

  init()

}

document.addEventListener('DOMContentLoaded', CMSWTInstantSearch)

//Run Elementor dependency code only on editor
if (defaultSettings.elementor_edit_mode === 'true') {
  window.addEventListener('elementor/frontend/init', () => {
    elementorFrontend.hooks.addAction('frontend/element_ready/cm-typesense-instant-search.default', CMSWTInstantSearch)
  })
}
